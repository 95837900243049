import React, { useEffect, useState } from 'react';
import './styles.scss';
import BannerSlider from '../../components/home/BannerSlider';
import { useAccount } from '../../store/account';
import TitleBox from '../../ui-components/title-box';
import ItemAuction from './item-auction';
import ItemSmallAuction from './item-small-auction';
import ContactForm from '../../components/contact';
import { useAuction } from '../../store/auction';
import { Constants } from '../../Constants';
import moment from 'moment';
import ItemAuctionBig from './item-auction-big';

const Home = () => {
  const accountStore = useAccount();
  const auctionStore = useAuction();

  const [auctionLists, setAuctionLists] = useState({
    inProgress: [],
    completed: [],
    pending: [],
  });

  useEffect(() => {
    //accountStore.getAccountConfig();
    auctionStore.getAllAuctions();
    document.title = 'Inicio';
  }, []);

  useEffect(() => {
    if (accountStore.config?.title_tabs) {
      document.title = accountStore.config.title_tabs + ' - ' + document.title;
    }
  }, [accountStore.config]);

  useEffect(() => {
    if (null != auctionStore.auctions) {
      setAuctionLists({
        ...auctionLists,
        inProgress: auctionStore.auctions.data.inProgress,
        completed: auctionStore.auctions.data.completed,
        pending: auctionStore.auctions.data.pending,
      });
    }
  }, [auctionStore.auctions]);

  useEffect(() => {
  }, [auctionLists]);

  return (
    <div className='home-box'>
      <BannerSlider />
      
      <section className='light' id="auctions-list">
        {0 < auctionLists.inProgress.length && (
          <div className='box container'>
            <div className='mb-5'>
              <TitleBox title='Subastas en proceso' />
            </div>
            {auctionLists.inProgress.map((item) => (
              <ItemAuctionBig
                name={item.title}
                description={item.description}
                auctionNumber={item.id}
                dateFrom={`${moment(item.date_from).format(
                  'dddd D'
                )} de ${moment(item.date_from).format('MMMM')}, ${moment(
                  item.date_from
                ).format('HH:mm')}hs.`}
                dateTo={`${moment(item.date_to).format('dddd D')} de ${moment(
                  item.date_to
                ).format('MMMM')}, ${moment(item.date_to).format('HH:mm')}hs.`}
                img={`${Constants.BASE_URL}${item.image}`}
                lotsCount={item.count_lot}
              />
            ))}
          </div>
        )}
        {0 < auctionLists.pending.length && (
          <div className='box container mt-5'>
            <div className='mb-5'>
              <TitleBox title='Próximas Subastas' />
            </div>

            <div className='row'>
              {auctionLists.pending.map((item) => (
                <div className='col-md-6 col-sm-12 mt-4'>
                  <ItemAuction
                    name={item.title}
                    auctionNumber={item.id}
                    dateFrom={`${moment(item.date_from).format(
                      'dddd D'
                    )} de ${moment(item.date_from).format('MMMM')}, ${moment(
                      item.date_from
                    ).format('HH:mm')}h.`}
                    dateTo={`${moment(item.date_to).format(
                      'dddd D'
                    )} de ${moment(item.date_to).format('MMMM')}, ${moment(
                      item.date_to
                    ).format('HH:mm')}h.`}
                    img={`${Constants.BASE_URL}${item.image}`}
                    lotsCount={item.count_lot}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </section>

      {0 < auctionLists.completed.length && (
        <section className='dark'>
          <div className='box container'>
            <div className='mb-5'>
              <TitleBox title='Subastas finalizadas' backgroundDark={true} />
            </div>

            <div className='row'>
              {auctionLists.completed.map((item) => (
                <div className='col-md-6 col-sm-12 position-relative mt-4'>
                  <ItemSmallAuction
                    name={item.title}
                    auctionNumber={item.id}
                    dateFinished={`${moment(item.date_to).format(
                      'dddd D'
                    )} de ${moment(item.date_to).format('MMMM')}, ${moment(
                      item.date_to
                    ).format('HH:mm')}h.`}
                    img={`${Constants.BASE_URL}${item.image}`}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
      <section className='light'>
        <div className='box container'>
          <div className='mb-5'>
            <TitleBox title='Contactanos' />
          </div>
          <div className='row'>
            <div className='col-3'></div>
            <div className='col-md-6 col-sm-12'>
              <ContactForm />
            </div>
            <div className='col-3'></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
