import React from 'react';
import { Link } from 'react-router-dom';
import { Constants } from '../../../Constants';
import { useAccount } from '../../../store/account';

const PrimaryMenu = () => {
  const accountStore = useAccount();

  return (
    <div className='primary-menu'>
      <div className='box-items container'>
        <div className='start-items'>
          <div className='item'>
            <Link to='/' className='link'>
              <img
                src={accountStore.config?.logo_web ? Constants.BASE_URL + accountStore.config.logo_web : ''}
                alt='logo'
                className='logo'
              />
            </Link>
          </div>
        </div>
        <div className='end-items'>
          <Link to='/' className='link'>
            <div className='item'>Inicio</div>
          </Link>
          <Link to='/terminos-condiciones' className='link'>
            <div className='item'>Término & condiciones</div>
          </Link>
          {
            accountStore?.config?.include_page_buy && (
              <Link to='/comprar' className='link'>
                <div className='item'>Comprar</div>
              </Link>
            )
          }
          {
            accountStore?.config?.include_page_sell && (
              <Link to='/vender' className='link'>
                <div className='item'>Vender</div>
              </Link>
            )
          }
          <Link to='/ayuda' className='link'>
            <div className='item'>F.A.Q</div>
          </Link>
          <Link to='/contacto' className='link'>
            <div className='item'>Contacto</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PrimaryMenu;
