import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import './Login.scss';
import { userLogin, userLoginFB } from '../services/UserServices';
import { connect } from 'react-redux';
import { userSetStorageAction } from '../redux/actions/UserActions';
import Loading from '../components/loading/Loading';
import ForgetPassword from '../components/modals/ForgetPassword';
import { Tabs, Tab } from 'react-bootstrap';
import Register from './register/Register';
import TitleBox from '../ui-components/title-box';

class Login extends Component {
    state = {
        email: '',
        password: '',
        messageError: '',
        loading: false,
        showForgetPassword: false,
        tab: 'login',
        auction: this.props.match.params.auction,
        y: this.props.match.params.y,
        segment: this.props.match.params.segment,
        title: 'Ingresar',
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.configAccount?.config !== this.props.configAccount?.config) {
            if (this.props.configAccount.config?.title_tabs !== null) {
                document.title = this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
            }
        }
    }

    changeTab = async (tab, e) => {
        if (e !== undefined && e.type !== "click") {
            return;
        }
        await this.setState({ tab: tab });
    }


    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: name === 'email' ? value.replace(/\s+/g, '') : value })
    }

    login = async (event) => {
        event.preventDefault();
        await this.setState({ loading: true });
        try {
            let response = await userLogin({
                email: this.state.email,
                password: this.state.password
            });
            await this.setState({ loading: false });
            if (this.state.auction && this.state.y && this.state.segment) {
                this.props.history.push(`/${this.state.segment}/${this.state.auction}/${this.state.y}`);
            }
            this.props.setLocalStorage(response.data.data);
        } catch (error) {
            await this.setState({ messageError: error.response.data.message, loading: false });
        }
    }

    loginFB = async (data) => {
        await this.setState({ loading: true });
        try {
            let response = await userLoginFB({
                email: data.email,
                fbId: data.id,
                name: data.name,
                json: data
            });
            await this.setState({ loading: false });
            if (this.state.auction && this.state.y && this.state.segment) {
                this.props.history.push(`/${this.state.segment}/${this.state.auction}/${this.state.y}`);
            }
            this.props.setLocalStorage(response.data.data);
        } catch (error) {
            await this.setState({ messageError: error.response.data.message, loading: false });
        }
    }

    closeModal = async () => {
        await this.setState({ showForgetPassword: false });
    }

    render() {
        const responseFacebook = (response) => {
            this.loginFB(response);
        }

        return (
            <React.Fragment>
                {this.state.showForgetPassword && <ForgetPassword close={() => this.closeModal()} />}
                {this.state.loading && <Loading />}
                <section className="login-area ptb-60 mobile-login">
                    <Tabs activeKey={this.state.tab} onSelect={(k, e) => this.changeTab(k, e)}
                        style={{ marginTop: '2rem', width: '100%', marginLeft: '1%', marginRight: '1%' }}>
                        <Tab eventKey="login" tabClassName="nav-tabs-custom nav-tabs-custom-login" title={<span> <i className="fas fa-user"></i> Ingresar</span>}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="login-content" style={{ marginTop: '2rem' }}>
                                        <form className="login-form" onSubmit={this.login} style={{ paddingLeft: '15px', color: 'black' }}>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input type="email" className="form-control" placeholder="Ingrese su email" name="email"
                                                    value={this.state.email} onChange={this.handleChange} required />
                                            </div>

                                            <div className="form-group">
                                                <label>Contraseña</label>
                                                <input type="password" className="form-control" placeholder="Ingrese su contraseña" name="password"
                                                    value={this.state.password} onChange={this.handleChange} required />
                                            </div>

                                            <div style={{ textAlign: 'center', color: 'red' }}>
                                                <label>{this.state.messageError}</label>
                                            </div>

                                            <button type="submit" className="btn button-with-degrade">Ingresar</button>

                                            <div className="w-100 text-center">
                                                <Link onClick={() => this.setState({ showForgetPassword: true })} className="forgot-password">
                                                    ¿Olvidó su contraseña?
                                                </Link>
                                            </div>
                                        </form>
                                        {
                                            this.props.configAccount.config?.facebook_app_id &&
                                            <div style={{ textAlign: 'center', width: '100%', marginTop: '1rem', marginBottom: '1rem' }}>
                                                {
                                                    this.props.configAccount.config?.facebook_app_id &&
                                                    <FacebookLogin
                                                        appId={this.props.configAccount.config?.facebook_app_id}
                                                        autoLoad={false}
                                                        fields="name,email"
                                                        callback={responseFacebook}
                                                        cssClass="facebook-button-class"
                                                        icon="fa-facebook"
                                                        textButton="Ingresar con Facebook"
                                                        disableMobileRedirect={true} />
                                                }
                                            </div>
                                        }



                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="register" tabClassName="nav-tabs-custom nav-tabs-custom-login" title={<span> <i className="fas fa-user"></i> Registrarse</span>}>
                            <Register external={true} {...this.props} />
                        </Tab>
                    </Tabs>
                </section>
                <section className="login-area ptb-60 desktop-login">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-7 mr-auto ml-auto">
                                <div className="login-content">
                                    <TitleBox title='Login' />
                                    <form className="login-form" onSubmit={this.login} style={{ marginTop: '1.5rem' }}>
                                        <div className="form-group">
                                            <input type="email" className="form-control" placeholder="Email" name="email"
                                                value={this.state.email} onChange={this.handleChange} required />
                                        </div>

                                        <div className="form-group">
                                            <input type="password" className="form-control" placeholder="Contraseña" name="password"
                                                value={this.state.password} onChange={this.handleChange} required />
                                        </div>

                                        <div style={{ textAlign: 'center', color: 'red' }}>
                                            <label>{this.state.messageError}</label>
                                        </div>

                                        <div className="w-100" style={{ display: 'flex' }}>
                                            <button type="submit" className="btn button-with-degrade">Ingresar</button>

                                            <div className="ml-auto" style={{
                                                alignItems: 'center',
                                                display: 'flex'
                                            }}>
                                                <Link onClick={() => this.setState({ showForgetPassword: true })} className="forgot-password">
                                                    ¿Olvidó su contraseña?
                                                </Link>
                                            </div>
                                        </div>

                                    </form>
                                    <div style={{ textAlign: 'center', width: '100%', marginTop: '1rem' }}>
                                        {
                                            this.props.configAccount.config?.facebook_app_id &&
                                            <FacebookLogin
                                                appId={this.props.configAccount.config?.facebook_app_id}
                                                autoLoad={false}
                                                fields="name,email"
                                                callback={responseFacebook}
                                                cssClass="facebook-button-class"
                                                icon="fa-facebook"
                                                textButton="Ingresar con Facebook"
                                                disableMobileRedirect={true} />
                                        }
                                    </div>

                                </div>
                            </div>

                            <div className="col-12 col-md-7 mr-auto ml-auto" style={{ marginTop: '4rem' }}>
                                <div className="new-customer-content">
                                    <TitleBox title='Registrarse' />
                                    <p className="text-center" style={{ marginTop: '1.5rem' }}>
                                        Para poder comenzar a ofertar, haga clic en "Crear cuenta".
                                    </p>
                                    <div className="w-100 text-center">
                                        <Link to="/registrar" className="text-mobile">
                                            <button className="btn btn-mobile button-with-degrade">Crear cuenta</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    setLocalStorage: (data) => {
        dispatch(userSetStorageAction(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
