import React from 'react';
import { Fragment } from 'react';
import { useAccount } from '../../../store/account';
import MobileMenu from './mobile';
import PrimaryMenu from './primary-menu';
import SecondaryMenu from './secondary-menu';
import './styles.scss';

const Menu = () => {
  const accountStore = useAccount();

  const getMenu = () => {
    const { innerWidth: width } = window;

    if (500 < width) {
      return desktopMenu();
    } else {
      return mobileMenu();
    }
  };

  const desktopMenu = () => (
    <Fragment>
      <SecondaryMenu />
      <PrimaryMenu />
      <a href={`https://wa.me/${accountStore.config?.whatsapp}`}
        className="whatsapp" target="_blank"> <i className="fab fa-whatsapp"></i>
      </a>
    </Fragment>
  );

  const mobileMenu = () => <MobileMenu />;

  return <div className='menu'>{getMenu()}</div>;
};

export default Menu;
